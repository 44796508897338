import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortalComponent } from './portal/portal.component';
import { PortalEmailComponent } from './portal-email/portal-email.component';
import { SharedModule } from '../shared/shared.module';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { bootstrapEnvelope } from '@ng-icons/bootstrap-icons';
import { NgIconsModule } from '@ng-icons/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { MatMomentDateModule } from '@angular/material-moment-adapter';

@NgModule({
  declarations: [
    PortalComponent,
    PortalEmailComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    AppRoutingModule,
    NgIconsModule.withIcons({
      bootstrapEnvelope
    }),
    MatSelectModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatDatepickerModule,
    MatButtonModule,
    MatMomentDateModule
  ],
  exports: [
    PortalComponent,
    PortalEmailComponent,
  ]
})
export class MyPortalModule { }
