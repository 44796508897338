
<h4>Email Configuration</h4>
<mat-form-field appearance="fill">
    <mat-label>Operations</mat-label>
    <mat-select [(ngModel)]="selectedOperation" name="operation" (selectionChange)="handleOperationChange()">
        <mat-option *ngFor="let operation of operations" [value]="operation.value">
        {{ operation['key'] }}
        </mat-option>
    </mat-select>
</mat-form-field>

<mat-form-field appearance="fill">
    <mat-label>Email Template</mat-label>
    <mat-select [(ngModel)]="selectedTemplate" name="template">
        <mat-option *ngFor="let template of emailTemplates" [value]="template.value">
        {{ template['key'] }}
        </mat-option>
    </mat-select>
</mat-form-field>

<mat-form-field appearance="fill">
    <mat-label>Target</mat-label>
    <mat-select [(ngModel)]="selectedTarget" name="target">
        <mat-option *ngFor="let target of targets" [value]="target.value">
        {{ target['key'] }}
        </mat-option>
    </mat-select>
</mat-form-field>

<mat-form-field appearance="fill">
    <mat-label>Locale</mat-label>
    <mat-select [(ngModel)]="selectedLocale" name="target">
        <mat-option *ngFor="let locale of locales" [value]="locale">
        {{ locale }}
        </mat-option>
    </mat-select>
</mat-form-field>


<h4>Parameters</h4>
<mat-form-field class="example-form-field" appearance="fill">
    <mat-label>Request ID</mat-label>
    <input matInput type="text" [(ngModel)]="rentRequestId">
    <button *ngIf="rentRequestId" matSuffix mat-icon-button aria-label="Clear" (click)="rentRequestId=''">
      <mat-icon>close</mat-icon>
    </button>
</mat-form-field>
<mat-form-field class="example-form-field" appearance="fill">
    <mat-label>Link</mat-label>
    <input matInput type="text" [(ngModel)]="link" >
    <button *ngIf="link" matSuffix mat-icon-button aria-label="Clear" (click)="link=''">
      <mat-icon>close</mat-icon>
    </button>
</mat-form-field>

<div class="row">
    <div class="col-auto">
        <button mat-raised-button color="primary" (click)="renderEmail()">Render</button>
    </div>
    
</div>

<iframe #emailPreview width="100%" height="1000px" [style.display]="emailContent.body ? 'block' : 'none'"></iframe>




  