import { Component, OnInit, ViewChild } from '@angular/core';
import { EmailService } from 'src/app/services/email.service';
import { FormControl, FormGroup} from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-portal-email',
  templateUrl: './portal-email.component.html',
  styleUrls: ['./portal-email.component.css'],
})
export class PortalEmailComponent implements OnInit {
  emailTemplates:any = [];
  selectedTemplate: string = "";
  locales: string[] = ['en', 'fr'];
  selectedLocale: string = 'en';
  operations: any = [];
  selectedOperation: string = "";
  targets: any = [];
  selectedTarget: string = "";
  link: string = "";
  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });
  rent: number | null = null;

  rentRequestId: string = "";
  emailParameterList: string[] = [];
  emailContent:any = {};
  populdateRentRequest: any = {};
  populdated: boolean = false;
  rendered: boolean = false;
  @ViewChild('emailPreview', { static: true }) emailPreviewIframe: any;

  constructor(
    private emailService: EmailService,
    private router: Router
  ) { 
    this.emailTemplates = [];
  }

  ngOnInit(): void {
    this.emailService.getEmailOperations().subscribe(resp => {
      const keys = Object.keys(resp as any);
      let res:any = [];
      keys.forEach((key) => {
        res.push({
          key: key,
          value: (resp as any)[key]
        });
      })
      this.operations = res; 
    }, error => {
      this.router.navigate(['/login']);
    })
    this.emailService.getEmailTargets().subscribe(resp => {
      const keys = Object.keys(resp as any);
      let res:any = [];
      keys.forEach((key) => {
        res.push({
          key: key,
          value: (resp as any)[key]
        });
      })
      this.targets = res;  
    }, error => {
      this.router.navigate(['/login']);
    })

  }

  handleOperationChange() {
    this.emailService.getEmailTemplateForOperation(this.selectedOperation).subscribe(resp => {
      let res = [] ;
      for (let i = 0; i < (resp as any).length; ++i) {
        res.push({
          key: (resp as any)[i],
          value: (resp as any)[i]
        })
      }
      this.emailTemplates = res;
    }, error => {
      this.router.navigate(['/login']);
    })
  }

  renderEmail() {
    let updates = {};
    if (this.selectedOperation == 'AdminUpdate') {
      updates = {
        date: true,
        price: true
      }
    }
    this.emailService.renderEmail(
      this.selectedTemplate, 
      this.selectedOperation, 
      this.selectedTarget, 
      this.rentRequestId, 
      this.link,
      updates, 
      this.selectedTarget == 'Admin',
      this.selectedLocale
    ).subscribe(resp => {
      this.emailContent = resp as any;

      // update iframe
      const iframeContent = this.emailPreviewIframe.nativeElement.contentDocument || this.emailPreviewIframe.nativeElement.contentWindow.document;
      iframeContent.open();
      iframeContent.write(this.emailContent.body);
      iframeContent.close();
      this.rendered = true;
    }, error => {
      this.router.navigate(['/login']);
    }) 
  }

  hasRentRequestScope() {
    for (let para of this.emailParameterList) {
      if (para.startsWith("rentRequest")) return true;
    }
    return false;
  }

  isRentRequestScope(parameter: string): boolean {
    return parameter.startsWith("rentRequest");
  }

  hasRentRequestMessage() {

  }

}
