import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Utils } from '../utils/utils';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(
    private http: HttpClient,
    private utils: Utils,
    private authService: AuthService
  ) { }

  sendVerificationCode(email: string) {
    const headers = 
      new HttpHeaders().set(
        'authorization', this.authService.getToken()
      );
    return this.http.post(
      this.utils.getAPIUrl() + 'users/sendemailcode',
      { email },
      { headers }
    )
  }

  getEmailTemplates() {
    const headers = 
      new HttpHeaders().set(
        'authorization', this.authService.getToken()
      );
    return this.http.get(
      this.utils.getAPIUrl() + 'admin/email/templates',
      { headers }
    );
  }

  getEmailTargets() {
    const headers = 
      new HttpHeaders().set(
        'authorization', this.authService.getToken()
      );
    return this.http.get(
      this.utils.getAPIUrl() + 'admin/email/targets',
      { headers }
    )
  }

  getEmailOperations() {
    const headers = 
      new HttpHeaders().set(
        'authorization', this.authService.getToken()
      );
    return this.http.get(
      this.utils.getAPIUrl() + 'admin/email/operations',
      { headers }
    )
  }

  getEmailTemplateForOperation(operation: string) {
    const headers = 
      new HttpHeaders().set(
        'authorization', this.authService.getToken()
      );
    return this.http.get(
      `${this.utils.getAPIUrl()}admin/email/${operation}/templates`,
      { headers }
    );
  }

  renderEmail(
    template: string, 
    operation: string, 
    target: string, 
    requestId: string, 
    link: string, 
    updates: object, 
    admin: boolean, 
    locale: string
  ) {
    const headers = 
      new HttpHeaders().set(
        'authorization', this.authService.getToken()
      );
    return this.http.post(
      this.utils.getAPIUrl() + "admin/email/render-template",
      {
        template,
        operation,
        target,
        requestId,
        link,
        updates,
        admin,
        locale
      },
      { headers }
    );
  }

}
