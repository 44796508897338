<nav class="navbar navbar-expand-lg bg-light">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">
      <img src="https://hivenue.ca/assets/images/logo.png" alt="Logo" class="d-inline-block align-text-top">
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
      <div class="navbar-nav">
        <a
          [class]="'nav-link' + (getUrlPath() === 'dashboard' ? ' active' : '') " href="/dashboard" >Dashboard</a>
        
        <a routerLink="/listing"
          [class]="'nav-link' +
             (getUrlPath() === 'listing' ||
              getUrlPath() === 'user' ||
              getUrlPath() === 'booking-request' ||
              getUrlPath() === 'reservation' ? ' active' : '')  "
          aria-current="page" href="#" *ngIf="!layoutService.isLessThanSizeForFullDisplay()">Management
        </a>
          <a
          class="nav-link"
          aria-current="page" href="#" *ngIf="layoutService.isLessThanSizeForFullDisplay()"
          style="display: inline-block">
            Management
            <br>
            <ul class="management_list">
              <li ><a class="nav-link" routerLink="/listing">Listings</a></li>
              <li ><a class="nav-link" routerLink="/user">Users</a></li>
              <li ><a class="nav-link" routerLink="/booking-request">Booking Requests</a></li>
              <li ><a class="nav-link" routerLink="/reservation">Reservations</a></li>
              <li ><a class="nav-link" routerLink="/invoice">Invoices</a></li>
            </ul>
          </a> 
        <a [class]="'nav-link' + (getUrlPath() === 'matching' ? ' active' : '') " href="/matching" >Matching</a>
        <a [class]="'nav-link' + (getUrlPath() === 'feedback' ? ' active' : '') " href="/feedback" >Feedback</a>
        <a [class]="'nav-link' + (getUrlPath() === 'portal' ? ' active' : '') " href='/portal'>Portal</a>
        <a class="nav-link disabled" href="#">Reports</a>
        <a class="nav-link disabled" href="#">Analytics</a>
      </div>
    </div>
    <button
      type="button"
      class="btn btn-outline-danger me-3"
      (click)="logout()"
      *ngIf="login.isLoggedIn()">Log Out</button>
  </div>
</nav>
