export const environment = {
  production: false,
  socketUrl: 'https://devapi.hivenue.ca',
  apiUrl: "https://devapi.hivenue.ca/",
  imageHost: "https://s3.ca-central-1.amazonaws.com/dev.hivenue.ca/",
  invoiceUrl: "https://dashboard.stripe.com/test/invoices/",
  stripeAccountId: "acct_1N7h7RAVT9RHsNRA",
  frontEndUrl: "https://devweb.hivenue.ca/",
  stripeUrl: "https://dashboard.stripe.com/test",
};
