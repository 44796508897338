import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { AvatarModule } from 'ngx-avatar';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NavigationComponent } from './components/general/navigation/navigation.component';
import { ListingsComponent } from './components/listings/listings.component';
import { ListingComponent } from './components/listings/listing/listing.component';
import { ListingDetailComponent } from './components/listings/listing-detail/listing-detail.component';
import { ListingRoutingModule } from './components/listings/listing-routing/listing-routing.module';
import { ReservationRoutingModule } from './components/reservations/reservation-routing/reservation-routing.module';
import { CheckboxListComponent } from './components/listings/listing-detail/checkbox-list/checkbox-list.component';
import { PhotoCarouselComponent } from './components/listings/listing-detail/photo-carousel/photo-carousel.component';
import { ClipboardModule } from 'ngx-clipboard';
import { TopbarComponent } from './components/general/topbar/topbar.component';
import { UsersComponent } from './components/users/users.component';
import { UserComponent } from './components/users/user/user.component';
import { UserDetailComponent } from './components/users/user-detail/user-detail.component';
import { UserRoutingModule } from './components/users/user-routing/user-routing.module';
import { ReservationsComponent } from './components/reservations/reservations.component';
import { ReservationComponent } from './components/reservations/reservation/reservation.component';
import { ReservationDetailComponent } from './components/reservations/reservation-detail/reservation-detail.component';
import { BookingRequestsComponent } from './components/booking-requests/booking-requests.component';
import { BookingRequestComponent } from './components/booking-requests/booking-request/booking-request.component';
import { BookingRequestDetailComponent } from './components/booking-requests/booking-request-detail/booking-request-detail.component';
import { BookingRequestsRoutingModule } from './components/booking-requests/booking-requests-routing/booking-requests-routing.module';
import { LoginComponent } from './components/login/login.component';
import { CookieService } from 'ngx-cookie-service';
import { GoogleMapsModule } from '@angular/google-maps';
import { MessageModalComponent } from './components/general/modal/message-modal/message-modal.component';
import { InvoicesComponent } from './components/invoices/invoices.component';
import { InvoiceComponent } from './components/invoices/invoice/invoice.component';
import { MatchingModule } from './components/matching/matching.module';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';
import { ToastsContainer } from './services/toast/toasts-container.component';
import { FeedbackModule } from './components/feedback/feedback.module'
import { PaginatorModule } from './components/general/paginator/paginator.module';
import { DashboardModule } from './components/dashboard/dashboard.module';
import { SharedModule } from './components/shared/shared.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LayoutModule } from '@angular/cdk/layout';
import { PayoutsComponent } from './components/payouts/payouts.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatBadgeModule } from '@angular/material/badge';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ReactiveFormsModule } from '@angular/forms';
import {MatCardModule} from '@angular/material/card';
import {MatGridListModule} from '@angular/material/grid-list';
import { PayoutConfrimModalComponent } from './components/general/modal/payout-confrim-modal/payout-confrim-modal.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { SentMessagesComponent } from './components/sent-messages/sent-messages.component';
import { MyPortalModule } from './components/portal/my-portal.module';
import { MatSidenavModule } from '@angular/material/sidenav';
const config: SocketIoConfig = {
	url: environment.socketUrl,
	options: {
		transports: ['websocket']
	}
}

@NgModule({
  declarations: [
    AppComponent,
    // DashboardComponent,
    LoginComponent,
    NavigationComponent,
    ListingsComponent,
    ListingComponent,
    ListingDetailComponent,
    CheckboxListComponent,
    PhotoCarouselComponent,
    TopbarComponent,
    UsersComponent,
    UserComponent,
    UserDetailComponent,
    ReservationsComponent,
    ReservationComponent,
    ReservationDetailComponent,
    BookingRequestsComponent,
    BookingRequestComponent,
    BookingRequestDetailComponent,
    MessageModalComponent,
    InvoicesComponent,
    InvoiceComponent,
    ToastsContainer,
    PayoutsComponent,
    PayoutConfrimModalComponent,
    SentMessagesComponent,
    // UpcomingCheckinComponent,
  ],
  imports: [
    BrowserModule,
    NgbModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ListingRoutingModule,
    ClipboardModule,
    UserRoutingModule,
    ReservationRoutingModule,
    ClipboardModule,
    BookingRequestsRoutingModule,
    GoogleMapsModule,
    MatchingModule,
    SocketIoModule.forRoot(config),
    FeedbackModule,
    PaginatorModule,
    DashboardModule,
    SharedModule,
    NgxSpinnerModule,
    LayoutModule,
    MatTableModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatBadgeModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatFormFieldModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatCardModule,
    MatGridListModule,
    MatDialogModule,
    MatTabsModule,
    MatSlideToggleModule,
    AvatarModule,
    MyPortalModule,
  ],
  providers: [
    CookieService,
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule { }