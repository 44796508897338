import { Component, OnInit } from '@angular/core';
import { Route } from '../../shared/sidebar/sidebar.types';

@Component({
  selector: 'app-portal',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.css']
})
export class PortalComponent implements OnInit {
  routes: Route[] = [
    {
      name: 'Email',
      href: '/portal/email',
      icon: 'bootstrapEnvelope'
    },
]
  constructor() { }

  ngOnInit(): void {
  }

}
